<template>
    <div>
        <Dialog v-model:visible="isModalVisible" :style="{ width: '400px' }" :header="isEdit ? 'Editing customer: ' + contextCustomer.name : 'New Customer'" :modal="true"
        @update:visible="hideDialog" @hide="hideDialog" class="p-fluid p-input-filled">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12" v-if="!isEdit">
                    <div class="field">
                        <label for="name">Name</label>
                        <InputText fluid class="mt-2" id="name" v-model="contextCustomer.name" :class="{ 'p-invalid': vuelidate?.name?.$errors.length }" />
                        <InputValidationMessage :vuelidate="vuelidate" name="name" />
                    </div>
                </div>
                <div class="col-span-12">
                    <div class="field">
                        <label for="displayName">Display Name</label>
                        <InputText fluid class="mt-2" id="displayName" v-model="contextCustomer.displayName" />
                    </div>
                </div>
            </div>

            <template #footer>
                <div class="c-dialog__footer">
                    <div class="c-dialog__footer--end">
                        <!-- <Button label="Cancel" icon="pi pi-times" class="p-button-outlined" @click="hideDialog" /> -->
                        <Button label="Save" icon="pi pi-check" class="p-button" @click="saveCustomer" />
                    </div>
                </div>
            </template>
        </Dialog>
    </div>

</template>

<script lang="ts">
import { CreateCustomerRequest, CustomerDto, UpdateCustomerRequest } from '@/api/contracts/models';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputValidationMessage from '../shared/InputValidationMessage.vue';

import { defineComponent, ref, watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

import { useCustomer } from '@/composables/useCustomer';
import { useCustomerUpdate } from '@/composables/mutations/useCustomerUpdate';
import { useCustomerCreate } from '~/composables/mutations/useCustomerCreate';
import { v4 as uuidv4 } from 'uuid'
import { useRequiredValidator } from '~/validation';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';

export default defineComponent({
    name: 'CustomerModal',

    components: {
        Button,
        InputText,
        Dialog,
        InputValidationMessage
    },

    props: {
        isVisible: {
            type: Boolean,
            required: true
        },

        customerId: {
            type: String,
            required: false
        }
    },

    setup(props, context) {
        const hideDialog = () => {
            context.emit('hideDialog')
        }

        const isEdit = ref(false)

        const contextCustomerId = ref(props.customerId ? props.customerId : '')
        watch (
            () => props.customerId, 
            () => {
                contextCustomerId.value = props.customerId ? props.customerId  : ''
                isEdit.value = contextCustomerId.value != '' ? true : false
            }, { deep: true, immediate: true})


        const customer = useCustomer(contextCustomerId!)
        const contextCustomer = ref(customer.value ? { ...customer.value} : {} as CustomerDto)

        watch(
            () => customer.value,
            () => {
                contextCustomer.value = customer.value ? { ...customer.value} : {} as CustomerDto
                isEdit.value = contextCustomerId.value != '' ? true : false
            },
            {
                immediate: true,
                deep: true
            }
        )

        const isModalVisible = ref(props.isVisible)
        const isSubmitted = ref(false)

        watch(
            () => props.isVisible,
            () => {
                isModalVisible.value = props.isVisible,
                isSubmitted.value = false
            },
            {
                immediate: true
            }
        )

        const createCustomerMutation = useCustomerCreate(
            useQueryClient()
        )

        const updateCustomerMutation = useCustomerUpdate(
            useQueryClient()
        )
        
        const app = useNuxtApp()

        const rules = {
            name: { required: useRequiredValidator(app.$i18n) }
        } as unknown as ValidationArgs<CustomerDto>

        const vuelidate = useVuelidate(rules, contextCustomer)

        const saveCustomer = async () => {
            const isFormCorrect = await vuelidate.value.$validate()

            if (!isFormCorrect)
            {
                return
            }

            if (isEdit.value) {
                const request = {
                    customerId: contextCustomer.value.id,
                    displayName: contextCustomer.value.displayName,
                } as UpdateCustomerRequest

                updateCustomerMutation.mutate(request)
            }
            else {
                const request = {
                    ...contextCustomer.value,
                    id: uuidv4()
                } as CreateCustomerRequest

                createCustomerMutation.mutate(request)

                context.emit('customerCreated', request.id)
            }

            hideDialog()
        }

        return {
            hideDialog,
            saveCustomer,
            contextCustomer,
            isModalVisible,
            isSubmitted,  
            isEdit,
            vuelidate      
        }
    }
})
</script>