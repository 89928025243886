import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { ProjectDto } from '@/api/contracts/models'
import { useCustomerApi } from '../api/useCustomerApi'

export function useProjectsQuery(customerId: Ref<string>) {
   const client = useCustomerApi()

   const isEnabled = computed(() => !!customerId.value)

   return useQuery([QueryKeys.Projects, customerId], () => client.listProjects(customerId.value), { enabled: isEnabled, staleTime: Infinity })
}

export function useProjectsQueryPrefetch(
   queryClient: QueryClient,
   customerId: Ref<string>
): Promise<void> {
   const client = useCustomerApi()
   return queryClient.prefetchQuery<ProjectDto[]>([QueryKeys.Projects, customerId], () =>
      client.listProjects(customerId.value)
   )
}
