import { HttpClient, RequestParams } from "./base/BaseHttpClient";

export class DiagnosticsApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Diagnostics
   * @name LogPageView
   * @request POST:/api/diagnostics/page-views/{viewName}
   * @response `200` `void` OK
   */
  logPageView = (
    viewName: string,
    query?: {
      customerId?: string;
      projectId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<void, any>({
        path: `/api/diagnostics/page-views/${viewName}`,
        method: "POST",
        credentials: "include",
        query: query,
        ...params,
      })
      .then((m) => m.data);
}
