import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import {
  CreateRecommendationTemplateRequest,
  RecommendationTemplateDto,
  UpdateRecommendationTemplateRequest,
} from "./contracts/models";

export class RecommendationTemplateApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags RecommendationTemplate
   * @name List
   * @request GET:/api/recommendation-templates
   * @response `200` `(RecommendationTemplateDto)[]` OK
   */
  list = (params: RequestParams = {}) =>
    this.http
      .request<RecommendationTemplateDto[], any>({
        path: `/api/recommendation-templates`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags RecommendationTemplate
   * @name Create
   * @request POST:/api/recommendation-templates
   * @response `200` `string` OK
   */
  create = (data: CreateRecommendationTemplateRequest, params: RequestParams = {}) =>
    this.http
      .request<string, any>({
        path: `/api/recommendation-templates`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags RecommendationTemplate
   * @name Update
   * @request PUT:/api/recommendation-templates/{recommendationTemplateId}
   * @response `200` `void` OK
   */
  update = (recommendationTemplateId: string, data: UpdateRecommendationTemplateRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/recommendation-templates/${recommendationTemplateId}`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags RecommendationTemplate
   * @name Delete
   * @request DELETE:/api/recommendation-templates/{recommendationTemplateId}
   * @response `200` `void` OK
   */
  delete = (recommendationTemplateId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/recommendation-templates/${recommendationTemplateId}`,
        method: "DELETE",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
}
