<template>
    <div>
        <Dialog v-model:visible="isModalVisible" :style="{ width: '400px' }" :header="isEdit ? 'Editing project: ' + contextProject.name : 'New Project'" :modal="true"
        @update:visible="hideDialog" @hide="hideDialog" class="p-fluid p-input-filled">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12" v-if="!isEdit">
                    <div class="field">
                        <label for="displayName">Name</label>
                        <InputText fluid class="mt-2" id="displayName" v-model="contextProject.name" :class="{ 'p-invalid': vuelidate?.name?.$errors.length }" />
                        <InputValidationMessage :vuelidate="vuelidate" name="name" />
                    </div>
                </div>
                <div class="col-span-12" v-if="!isEdit">
                    <div class="field">
                        <label for="displayName">Short Name</label>
                        <InputText fluid class="mt-2" id="displayName" v-model="contextProject.shortName" />
                    </div>
                </div>
                <div class="col-span-12">
                    <div class="field">
                        <label for="displayName">Display Name</label>
                        <InputText fluid class="mt-2" id="displayName" v-model="contextProject.displayName" />
                    </div>
                </div>
            </div>

            <template #footer>
                <div class="c-dialog__footer">
                    <div class="c-dialog__footer--end">
                        <!-- <Button label="Cancel" icon="pi pi-times" class="p-button-outlined" @click="hideDialog" /> -->
                        <Button label="Save" icon="pi pi-check" class="p-button" @click="saveProject" />
                    </div>
                </div>
            </template>
        </Dialog>
    </div>

</template>

<script lang="ts">
import { CreateProjectRequest, ProjectDto, UpdateProjectRequest } from '@/api/contracts/models';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputValidationMessage from '../shared/InputValidationMessage.vue';

import { defineComponent, ref, watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

import { useProjectUpdate } from '@/composables/mutations/useProjectUpdate';
import { useProjectCreate } from '@/composables/mutations/useProjectCreate';
import { useProject } from '@/composables/useProject';

import { v4 as uuidv4 } from 'uuid'
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { useRequiredValidator } from '~/validation';

export default defineComponent({
    name: 'ProjectModal',

    components: {
        Button,
        InputText,
        Dialog,
        InputValidationMessage
    },

    props: {
        isVisible: {
            type: Boolean,
            required: true
        },
        projectId: {
            type: String,
            required: false
        },
        customerId: {
            type: String,
            required: false
        }
    },

    setup(props, context) {
        const isEdit = ref(false)

        const hideDialog = () => {
            context.emit('hideDialog')
        }

        const contextProjectId = ref(props.projectId ? props.projectId : '')

        watch (
            () => props.projectId, 
            () => {
                contextProjectId.value = props.projectId ? props.projectId : ''
                isEdit.value = contextProjectId.value ? true : false
            },
        {
            immediate: true,
            deep: true
        })

        const project = useProject(contextProjectId)
        const contextProject = ref(project.value ? { ...project.value} : {} as ProjectDto)

        watch(
            () => project.value,
            () => {
                contextProject.value = project.value ? { ...project.value} : {} as ProjectDto
            },
            {
                immediate: true,
                deep: true
            }
        )

        const isModalVisible = ref(props.isVisible)
        const isSubmitted = ref(false)

        watch(
            () => props.isVisible,
            () => {
                isModalVisible.value = props.isVisible,
                isSubmitted.value = false
            },
            {
                immediate: true
            }
        )

        const createProjectMutation = useProjectCreate(
            useQueryClient()
        )

        const updateProjectMutation = useProjectUpdate(
            useQueryClient()
        )

        const app = useNuxtApp()

        const rules = {
            name: { required: useRequiredValidator(app.$i18n) }
        } as unknown as ValidationArgs<ProjectDto>

        const vuelidate = useVuelidate(rules, contextProject)

        const saveProject = async () => {
            const isFormCorrect = await vuelidate.value.$validate()

            if (!isFormCorrect) return

            if (isEdit.value) {
                const request = {
                    projectId: contextProjectId.value,
                    displayName: contextProject.value.displayName,
                } as UpdateProjectRequest

                updateProjectMutation.mutate(request)
            }
            else {
                const request = {
                    ...contextProject.value,
                    id: uuidv4(),
                    customerId: props.customerId,
                } as CreateProjectRequest

                createProjectMutation.mutate(request)

                context.emit('projectCreated', request.id)
            }

            hideDialog()
        }

        return {
            hideDialog,
            saveProject,
            contextProject,
            isModalVisible,
            isSubmitted, 
            isEdit,
            vuelidate
        }
    }
})
</script>