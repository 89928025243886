import { HttpClient, RequestParams } from "./base/BaseHttpClient";
import { RecommendationBusinessGoalImpactDto, RecommendationBusinessGoalLinkDto } from "./contracts/models";

export class RecommendationBusinessGoalLinkApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags RecommendationBusinessGoalLink
   * @name List
   * @request GET:/api/recommendation-business-goal-links
   * @response `200` `(RecommendationBusinessGoalLinkDto)[]` OK
   */
  list = (params: RequestParams = {}) =>
    this.http
      .request<RecommendationBusinessGoalLinkDto[], any>({
        path: `/api/recommendation-business-goal-links`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags RecommendationBusinessGoalLink
   * @name ListImpacts
   * @request GET:/api/recommendation-business-goal-links/{businessGoalId}
   * @response `200` `(RecommendationBusinessGoalImpactDto)[]` OK
   */
  listImpacts = (businessGoalId: string, params: RequestParams = {}) =>
    this.http
      .request<RecommendationBusinessGoalImpactDto[], any>({
        path: `/api/recommendation-business-goal-links/${businessGoalId}`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
}
