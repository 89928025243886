<template>
   <div
      class="flex items-center justify-between lg:justify-start px-8 relative lg:static"
      style="height:60px"
   >
      <a
         v-ripple
         class="cursor-pointer block lg:hidden text-surface-700 dark:text-surface-100 mr-4 mt-1 p-ripple"
         v-styleclass="{
            selector: '#app-sidebar-2',
            enterFromClass: 'hidden',
            enterActiveClass: 'animate-fadeinleft',
            leaveToClass: 'hidden',
            leaveActiveClass: 'animate-fadeoutleft',
            hideOnOutsideClick: true
         }"
      >
         <i class="pi pi-bars text-4xl"></i>
      </a>
     <!-- <a v-ripple class="cursor-pointer block lg:hidden text-surface-700 dark:text-surface-100 p-ripple"
         v-styleclass="{ selector: '@next', enterClass: 'hidden',
         enterActiveClass: 'animate-fadein', leaveToClass: 'hidden', leaveActiveClass:
         'animate-fadeout', hideOnOutsideClick: true }">
         <span>Actions</span>
      </a> -->
      <ul
         class="list-none sm:hidden p-0 mt-0 mb-0 ml-0 mr-3 lg:mr-0 lg:flex lg:align-items-center select-none lg:flex-row border-round
                border-1 lg:border-none cph-surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static surface-ground lg:w-full"
      >         
         <li > 
            <a
               v-ripple
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 dark:text-white hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple"
               v-styleclass="{ selector: '.profile-dropdown', enterFromClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }"
            >
               <i class="pi pi-user text-base lg:text-xl mr-2 lg:mr-0"></i>
               <span class="block lg:hidden font-medium">Profile</span>
            </a>
            <ul class="list-none py-0 pl-4 m-0 xl:px-0 xl:py-0 dark:text-black bg-white rounded-border shadow-0 xl:shadow xl:absolute hidden origin-top w-full xl:w-60 cursor-pointer profile-dropdown z-50">
               <li>
                  <div class="flex p-4 items-center cursor-auto">
                     <i class="pi pi-user mr-2"></i>
                     <span class="font-medium">{{ userProfile?.email }}</span>
                  </div>
               </li>
               <li class="logout">
                  <a v-ripple class="flex p-4 items-center transition-colors duration-150 p-ripple"
                     @click="navigateTo('/logout')">
                     <i class="pi pi-sign-out mr-2"></i>
                     <span class="font-medium">Logout</span>
                  </a>
               </li>
            </ul>
         </li>
         <li v-if="userProjectSettings?.isAdminNotImpersonatingRegularUser">
            <a
               v-ripple
               @click="$emit('businessGoalEmailClicked')"
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple dark:text-white"
            >
               <i class="pi pi-inbox text-base lg:text-xl mr-2 lg:mr-0"></i>
               <span class="block lg:hidden font-medium">Business goal emails</span>
            </a>
         </li>
         <li v-if="userProjectSettings?.isAdminNotImpersonatingRegularUser">
            <a
               v-ripple
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 dark:text-white hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple"
            >
               <i
                  class="pi pi-bell text-base lg:text-xl mr-2 lg:mr-0"
                  v-badge.danger
               ></i>
               <span class="block lg:hidden font-medium">Notifications</span>
            </a>
         </li>
         <li v-if="userProjectSettings?.isAdminNotImpersonatingRegularUser">
            <a
               v-ripple
               @click="toggleDarkMode()"
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 dark:text-white hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple"
            >
               <i class="pi pi-sun text-base lg:text-xl mr-2 lg:mr-0"></i>
               <span class="block lg:hidden font-medium">Dark Mode</span>
            </a>
         </li>
         <li v-if="userProjectSettings?.isAdminNotImpersonatingRegularUser">
            <a
               v-ripple
               @click="$emit('customerEditClicked')"
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 dark:text-white hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple"
            >
               <i class="pi pi-cog text-base lg:text-xl mr-2 lg:mr-0"></i>
               <span class="block lg:hidden font-medium">Customer Settings</span>
            </a>
         </li>
      </ul>
      <CustomerSelector v-if="userProfile?.isAdmin"/>
   </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import CustomerSelector from '@/components/nav/CustomerSelector.vue'

import { useUserProfileQuery } from '@/composables/queries/useUserProfileQuery'


export default defineComponent({
   name: 'TopBar',

   components: {
      CustomerSelector
   },

   setup() {
      const { data:userProfile } = useUserProfileQuery()
      const userProjectSettings = useUserProjectSettings()

      const pinia = usePinia()
      const appModeStore = useAppModeStore(pinia)

      const { isDarkMode } = storeToRefs(appModeStore)

      const toggleDarkMode = () => {
         document.querySelector("html")!.classList.toggle("dark");
         document.querySelector("body")!.classList.toggle("dark");
         appModeStore.setDarkMode(!isDarkMode.value)
      }

      return {
         userProfile,
         userProjectSettings,
         toggleDarkMode
      }
   }
})
</script>

<style lang="scss">
.profile-dropdown {
   color: #495057;
}

.logout{
   &:hover {
      color: #4338CA;
  background: #EEF2FF;
   }
}
</style>
