import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import { CreateCustomerRequest, CustomerDto, ProjectDto, UpdateCustomerRequest } from "./contracts/models";

export class CustomerApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Customer
   * @name List
   * @request GET:/api/customers
   * @response `200` `(CustomerDto)[]` OK
   */
  list = (params: RequestParams = {}) =>
    this.http
      .request<CustomerDto[], any>({
        path: `/api/customers`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Customer
   * @name Create
   * @request POST:/api/customers
   * @response `200` `void` OK
   */
  create = (data: CreateCustomerRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/customers`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Customer
   * @name ListProjects
   * @request GET:/api/customers/{customerId}/projects
   * @response `200` `(ProjectDto)[]` OK
   */
  listProjects = (customerId: string, params: RequestParams = {}) =>
    this.http
      .request<ProjectDto[], any>({
        path: `/api/customers/${customerId}/projects`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Customer
   * @name Update
   * @request PUT:/api/customers/{customerId}
   * @response `200` `void` OK
   */
  update = (customerId: string, data: UpdateCustomerRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/customers/${customerId}`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
}
