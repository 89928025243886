<template>
    <div class="mainview">
      <div
         v-show="isDataLoading"
      >
         <LoadingScreen />
      </div>
      <div
         v-if="!isDataLoading"
         class="min-h-screen flex relative lg:static"
      >
         <SideMenu />
         <div class="min-h-screen flex flex-col relative flex-auto l-main-view-wrap">
            <TopBar 
            @customerEditClicked="isCustomerDialogVisible = true" 
            @projectEditClicked="isProjectDialogVisible = true"
            @businessGoalEmailClicked="isBusinessGoalDialogVisible = true"/>
            <div class="min-h-screen flex flex-col relative flex-auto l-main-view px-8">
               <slot />
            </div>
         </div>
      </div>

      <CustomerDialog :is-visible="isCustomerDialogVisible" @hideDialog="isCustomerDialogVisible = false"/>
      <ProjectDialog :is-visible="isProjectDialogVisible" @hideDialog="isProjectDialogVisible = false"/>
      <BusinessGoalEmailDialog :is-visible="isBusinessGoalDialogVisible" @hideDialog="isBusinessGoalDialogVisible = false"/>
   </div>
  </template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, KeepAlive } from 'vue'

import TopBar from '@/components/nav/TopBar.vue'

// import worker from '@/.demo/apiMock'

import { useQueryClient } from '@tanstack/vue-query'
import { useCustomersQueryPrefetch } from '@/composables/queries/useCustomersQuery'

import { useRouter } from 'vue-router'

import CustomerDialog from '@/components/customers/CustomerDialog.vue'
import ProjectDialog from '@/components/projects/ProjectDialog.vue'

import { useRecommendationTemplatesQueryPrefetch } from '@/composables/queries/useRecommendationTemplatesQuery'
import { useAuditInstancesQueryPrefetch } from '@/composables/queries/useAuditInstancesQuery'
import { useAuditTemplatesQueryPrefetch } from '@/composables/queries/useAuditTemplatesQuery'
import BusinessGoalDialog from '@/components/business-goals/BusinessGoalDialog.vue'
import BusinessGoalEmailDialog from '@/components/business-goals/BusinessGoalEmailDialog.vue'

import LoadingScreen from '@/components/shared/LoadingScreen.vue'
import { useBusinessGoalDataResultsQueryPrefetch } from '~/composables/queries/useBusinessGoalDataResultsQuery'
import { useUserProfileQuery } from '~/composables/queries/useUserProfileQuery'
import SideMenu from '~/components/nav/SideMenu.vue'

export default defineComponent({
   name: 'MainView',
   components: {
    LoadingScreen,
    TopBar,
    SideMenu,
    CustomerDialog,
    ProjectDialog,
    BusinessGoalDialog,
    BusinessGoalEmailDialog
},
   setup() {
      const queryClient = useQueryClient()

      const isDataLoading = ref(true)
      
      const app = useNuxtApp()
      const router = useRouter()

   //    watch (
   //       () => router.currentRoute, 
   //       () => app.$appInsights.trackPageView(), 
   //       { deep: true, immediate: true}
   //   )

      Promise.all([
         useUserProfileQuery(),
         useCustomersQueryPrefetch(queryClient),
         useRecommendationTemplatesQueryPrefetch(queryClient),
         useAuditInstancesQueryPrefetch(queryClient),
         useAuditTemplatesQueryPrefetch(queryClient),
         useBusinessGoalDataResultsQueryPrefetch(queryClient)
      ]).then(() => {
         isDataLoading.value = false
      })

      const isCustomerDialogVisible = ref(false)
      const isProjectDialogVisible = ref(false)
      const isBusinessGoalDialogVisible = ref(false)

      return {
         isDataLoading,
         isCustomerDialogVisible,
         isProjectDialogVisible,
         isBusinessGoalDialogVisible
      }
   }
})
</script>

<style lang="scss">
.loading-spinner-container {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
}

.l-main-view {
   max-width: 1660px;
}

.l-main-view-wrap {
   overflow-x: auto;
   @media all and (min-width: 992px) {
      margin-left: 220px;
   }

}
</style>