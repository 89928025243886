import { defineStore } from 'pinia'

export const useAppModeStore = defineStore('appMode', {
  state: () => ({
    isDarkMode: false
  }),
  actions: {
    setDarkMode(isDarkMode: boolean) {
        this.isDarkMode = isDarkMode
    }
  }
})