<template>
    <div class="c-business-goal-value-selector w-full" v-if="contextGoal">
        <div class="field flex flex-col" v-if="goalConfig">
            <label for="value" v-if="showLabel" class="block">
                Target
            </label>

            <InputNumber id="value" v-model="model"
                :suffix="' ' + getMetricUnit(contextGoal?.metric, contextGoal.coreWebVitalsMetric)"
                @input="onChange"
                :minFractionDigits="minFractionDigits"
                class="mt-2 w-full"
                :fluid="true"
                :class="{ 'p-invalid': vuelidate?.value?.$errors.length }" />

            <Slider v-model="innerModel"                     
                :min="goalConfig!.min"
                :max="goalConfig!.max"
                class="mt-2 w-full"
                @change="onSliderChange($event)"
                :step="goalConfig!.step" />

            <InputValidationMessage name="value" :vuelidate="vuelidate" />
        </div>
    </div>
</template>

<script lang="ts">
import { Validation } from '@vuelidate/core';
import { BusinessGoalDto, CoreWebVitalsMetric, Metric } from '~/api/contracts/models';
import { useMetricUnitLookup } from '~/composables/data/useMetricUnitLookup';
import InputValidationMessage from '../shared/InputValidationMessage.vue';

import Slider from 'primevue/slider';
import useBusinessGoalValueConfig from '~/composables/data/useBusinessGoalValueConfig';

export default defineComponent({
    components: {
        InputValidationMessage,
        Slider
    },
    props: {
        modelValue: {
            type: [ String, Number ]
        },

        goal: {
            type: Object as PropType<BusinessGoalDto>,
            required: true
        },

        vuelidate: {
            type: Object as PropType<Validation>,
            required: true
        },

        showLabel: {
            type: Boolean,
            default: true
        }
    },

    setup(props, context) {
        const contextGoal = ref(props.goal ? { ...props.goal } : {})

        watch(
            () => props.goal, 
            () => {
                contextGoal.value = props.goal ? { ...props.goal } : {}
            },
        { deep: true})

        const metricUnitsLookup = useMetricUnitLookup()
        const getMetricUnit = (name: any, cwvName: any) => {
            if (name && name == 'CoreWebVitals' && cwvName) {
                const result = metricUnitsLookup.value ? metricUnitsLookup.value[cwvName] : null

                if (result)
                    return result

                    return ''
            }

            return metricUnitsLookup.value ? metricUnitsLookup.value[name] : ''
        }

        const config = useBusinessGoalValueConfig()

        const goalConfig = computed(() => {
            return config.find(c => c.metric == contextGoal.value.metric || c.metric == contextGoal.value.coreWebVitalsMetric)
        })

        watch (() => goalConfig, () => {
            const inModel = goalConfig.value?.inValueMapping(Number(props.modelValue))

            innerModel.value = Number.parseFloat(inModel)
            
            if (props.modelValue != undefined) {
                model.value = Number(props.modelValue)
            }
            else {
                console.log('setting default')
                context.emit('update:modelValue', goalConfig.value?.outValueMapping(goalConfig.value.min))
            }
 
        }, { deep: true})

        const model = ref(props.modelValue ? Number(props.modelValue) : goalConfig.value?.outValueMapping(goalConfig.value.min))
        watch (
            () => props.modelValue,
            () => model.value = props.modelValue ? Number(props.modelValue) : goalConfig.value?.outValueMapping(goalConfig.value.min)
        )

        const innerModel = ref(goalConfig?.value?.inValueMapping(props.modelValue))
        watch (
            () => props.modelValue, 
            () => {
                const inModel = goalConfig.value?.inValueMapping(Number(props.modelValue))

                innerModel.value = Number.parseFloat(inModel)
        }, { immediate: true})

        const onChange = (event) => {
            context.emit('update:modelValue', event.value)

            event.originalEvent.stopPropagation()
        }

        const onSliderChange = (event) => {
            const result = goalConfig?.value!.outValueMapping(event)

            context.emit('update:modelValue', result)
        }

        const minFractionDigits = computed(() => {
            let fractionMetrics = [ 
                Metric.ThirtyDaysAvailabilityForApplication, 
                Metric.ThirtyDaysAvailabilityForInfrastructure,
                CoreWebVitalsMetric.CumulativeLayoutShift,   
            ]

            return fractionMetrics.some(x => contextGoal.value.metric == x || contextGoal.value.coreWebVitalsMetric == x) ? 1 : 0
        })

        return {
            contextGoal,
            getMetricUnit,
            onChange,
            model,
            goalConfig,
            innerModel,
            onSliderChange,
            minFractionDigits
        }
    }
})
</script>