import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { ProjectDto } from '@/api/contracts/models'
import { useProjectApi } from '../api/useProjectApi'

export function useUserProjectsQuery() {
    const client = useProjectApi()

    return useQuery([QueryKeys.UserProjects], () => client.getUserProjects(), { staleTime: Infinity })
}

export function useProjectsQueryPrefetch(queryClient: QueryClient): Promise<void> {
    const client = useProjectApi()
    return queryClient.prefetchQuery<ProjectDto[]>([QueryKeys.UserProjects], () =>
        client.getUserProjects()
    )
}
