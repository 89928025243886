import { defineNuxtPlugin } from "nuxt/app";

import PrimeVue from "primevue/config";

import StyleClass from "primevue/styleclass";

import DataTableFilters from "~/components/shared/DataTableFilters.vue";
import DataTableInnerHeading from "~/components/shared/DataTableInnerHeading.vue";
import ProjectCard from "~/components/shared/ProjectCard.vue";


export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("styleclass", StyleClass);

  nuxtApp.vueApp.component("DataTableFilters", DataTableFilters);
  nuxtApp.vueApp.component("DataTableInnerHeading", DataTableInnerHeading);

  nuxtApp.vueApp.component("ProjectCard", ProjectCard);
});
