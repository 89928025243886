export const appHead = {"meta":[{"name":"viewport","content":"viewport-fit: cover, width: device-width, initial-scale=1.0"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon-32x32.png"}],"style":[],"script":[],"noscript":[],"title":"(UAT) Hub | idoedge.com","bodyAttrs":{"class":"surface-500 dark:surface-0"},"viewport":"viewport-fit: cover, width: device-width, initial-scale=1.0"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'