import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import {
  CreateUserProfileRequest,
  PersistExpandedDeploymentsRequest,
  UpdateContextRequest,
  UpdateUserProfileRequest,
  UserProfileDto,
} from "./contracts/models";

export class UserProfileApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags UserProfile
   * @name Get
   * @request GET:/api/user-profile
   * @response `200` `UserProfileDto` OK
   */
  get = (params: RequestParams = {}) =>
    this.http
      .request<UserProfileDto, any>({
        path: `/api/user-profile`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProfile
   * @name CreateUserProfile
   * @request POST:/api/user-profile
   * @response `200` `void` OK
   */
  createUserProfile = (data: CreateUserProfileRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/user-profile`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProfile
   * @name UpdateContext
   * @request POST:/api/user-profile/context
   * @response `200` `void` OK
   */
  updateContext = (data: UpdateContextRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/user-profile/context`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProfile
   * @name PersistExpandedDeployments
   * @request PUT:/api/user-profile/context/expanded-deployments
   * @response `200` `void` OK
   */
  persistExpandedDeployments = (data: PersistExpandedDeploymentsRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/user-profile/context/expanded-deployments`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProfile
   * @name UpdateUserProfile
   * @request PUT:/api/user-profile/{userProfileId}
   * @response `200` `void` OK
   */
  updateUserProfile = (userProfileId: string, data: UpdateUserProfileRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/user-profile/${userProfileId}`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProfile
   * @name GetUserProfiles
   * @request GET:/api/user-profile/list
   * @response `200` `(UserProfileDto)[]` OK
   */
  getUserProfiles = (params: RequestParams = {}) =>
    this.http
      .request<UserProfileDto[], any>({
        path: `/api/user-profile/list`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
}
