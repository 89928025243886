import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import {
  AnonymizationRuleDto,
  UpdateUserProjectDisplaySettingsRequest,
  UserProjectDisplaySettingsDto,
} from "./contracts/models";

export class UserProjectDisplaySettingsApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags UserProjectDisplaySettings
   * @name Get
   * @request GET:/api/user-project-display-settings/{projectId}
   * @response `200` `UserProjectDisplaySettingsDto` OK
   */
  get = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<UserProjectDisplaySettingsDto, any>({
        path: `/api/user-project-display-settings/${projectId}`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProjectDisplaySettings
   * @name ListAnonymizationRules
   * @request GET:/api/user-project-display-settings/{projectId}/rules
   * @response `200` `(AnonymizationRuleDto)[]` OK
   */
  listAnonymizationRules = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<AnonymizationRuleDto[], any>({
        path: `/api/user-project-display-settings/${projectId}/rules`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProjectDisplaySettings
   * @name Update
   * @request PUT:/api/user-project-display-settings
   * @response `200` `void` OK
   */
  update = (data: UpdateUserProjectDisplaySettingsRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/user-project-display-settings`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
}
