import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/app/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ChartJs_plugin_o9VQVN3dJH from "/app/plugins/ChartJs.plugin.ts";
import HttpClient_plugin_rydQb9CWkb from "/app/plugins/HttpClient.plugin.ts";
import lodash_plugin_ZHKM8WwIY5 from "/app/plugins/lodash.plugin.ts";
import msal_client_gxY03bskzg from "/app/plugins/msal.client.ts";
import primevue_plugin_kr8slZLk6R from "/app/plugins/primevue.plugin.ts";
import toast_client_9z8IIRO0Dx from "/app/plugins/toast.client.ts";
import vue_query_plugin_Dis1xKCVan from "/app/plugins/vue-query.plugin.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  chunk_reload_client_UciE0i6zes,
  ChartJs_plugin_o9VQVN3dJH,
  HttpClient_plugin_rydQb9CWkb,
  lodash_plugin_ZHKM8WwIY5,
  msal_client_gxY03bskzg,
  primevue_plugin_kr8slZLk6R,
  toast_client_9z8IIRO0Dx,
  vue_query_plugin_Dis1xKCVan
]