import { HttpClient, RequestParams } from "./base/BaseHttpClient";
import { AuditInstanceDto } from "./contracts/models";

export class AuditInstanceApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AuditInstance
   * @name List
   * @request GET:/api/audit-instance
   * @response `200` `(AuditInstanceDto)[]` OK
   */
  list = (params: RequestParams = {}) =>
    this.http
      .request<AuditInstanceDto[], any>({
        path: `/api/audit-instance`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags AuditInstance
   * @name RunAudit
   * @request POST:/api/audit-instance/{auditInstanceId}
   * @response `200` `void` OK
   */
  runAudit = (auditInstanceId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/audit-instance/${auditInstanceId}`,
        method: "POST",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
}
