<template>
   <template v-if="children && children.length > 0 && isElementActive">
      <a v-ripple
         :class="{ 'router-link-active' : $route.path.endsWith('dashboard') }"
         class="flex items-center cursor-pointer p-4 rounded-border text-white hover:text-primary-500 hover:bg-white dark:hover:text-black dark:hover:bg-surface-700 duration-150 transition-colors p-ripple"
         @click="$emit('click')"
         >
         <i class="mr-2" :class="icon"></i>
         <span>{{ name }}</span>
      </a>
      <ul class="list-none py-0 pl-4 pr-0 m-0 overflow-y-hidden transition-all duration-[400ms] ease-in-out"
         :class="{ hidden: !isElementActive }">
         <li v-for="child of children" v-bind:key="child.id">
            <template v-if="child.children">
               <a 
                     :class="{ 'router-link-active' : child.children.find(c => c.to.name == $route.name) }"
                     v-ripple class="flex items-center cursor-pointer p-4 text-white hover:text-primary-500 rounded-border dark:hover:text-black hover:bg-white dark:hover:bg-surface-850 duration-150 transition-colors p-ripple"
                     v-styleclass="{ selector: '#ul-' + child.id , enterFromClass: 'hidden', enterActiveClass: 'animate-slidedown', leaveToClass: 'hidden', leaveActiveClass: 'animate-slideup' }">
                     <i :class="child.icon" class="mr-2"></i>
                     <span>{{ child.name }}</span>
                     <i class="pi pi-chevron-down ml-auto"></i>
                  </a>
                  <ul :id="'ul-' + child.id" :class="{ hidden: !child.children.find(c => c.to.name == $route.name) }" class="list-none py-0 pl-4 pr-0 m-0 overflow-y-hidden transition-all duration-[400ms] ease-in-out">
                     <li v-for="nestedChild of child.children" v-bind:key="nestedChild.id">
                        <NuxtLink :to="nestedChild.to" v-ripple
                           v-if="!nestedChild.isHidden && (!nestedChild.requireAdmin || (nestedChild.requireAdmin && userProjectSettings?.isAdminNotImpersonatingRegularUser))"
                              class="flex items-center cursor-pointer p-4 rounded-border text-white hover:text-primary-500 dark:hover:text-black hover:bg-white dark:hover:bg-surface-700 duration-150 transition-colors p-ripple env">
                              <i :class="nestedChild.icon" class="mr-2"></i>
                              <span>{{ nestedChild.name }}</span>
                           </NuxtLink >
                     </li>
                  </ul>
            </template>
            <template v-else>
               <NuxtLink :to="child.to" v-ripple
                  v-if="!child.isHidden && (!child.requireAdmin || (child.requireAdmin && userProjectSettings?.isAdminNotImpersonatingRegularUser))"
                  class="flex items-center cursor-pointer p-4 rounded-border text-white hover:text-primary-500 hover:bg-white dark:hover:text-black dark:hover:bg-surface-700 duration-150 transition-colors p-ripple env">
                  <i :class="child.icon" class="mr-2"></i>
                  <span>{{ child.name }}</span>
               </NuxtLink >
            </template>

         </li>
      </ul>
   </template>
   <template v-else>
      <NuxtLink  
         :active-class="activeClass" :to="to" v-ripple
         :class="{ 'router-link-active' : name == 'Admin Panel' && $route.path.endsWith('admin')  || name == 'Audits' && $route.path.endsWith('audit-instances')}"
         @click="$emit('click')"
         class="flex items-center cursor-pointer p-4 rounded-border text-white hover:text-primary-500 hover:bg-white dark:hover:bg-surface-700 dark:hover:text-black duration-150 transition-colors p-ripple">
         <i class="mr-2" :class="icon"></i>
         <span>
            {{ name }}
         </span>
      </NuxtLink >
   </template>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import { useParams } from '@/composables/utils/useParams'
import { useUserProfileQuery } from '~/composables/queries/useUserProfileQuery'

export default defineComponent({
   props: {
      to: Object,
      name: String,
      icon: String,
      activeClass: {
         type: String,
         default: 'router-link-active'
      },
      children: Array,
      requireAdmin: Boolean,
   },
   
   emits: ['click'],

   setup(props, context) {
      const { projectId } = useParams()

      const isElementActive = computed(() => {
         if (props.children && props.children.length > 0) {
           var result = props.children.filter(
               p => (p as any).to && (p as any).to.params.projectId == projectId.value || 
               (p as any).children && (p as any).children.find(c => c.projectId)
            ).length > 0

            return result
         }

         return (
            projectId.value == props.to!.params.projectId
         )
      })
      
      const { data : userProfile } = useUserProfileQuery()
      const userProjectSettings = useUserProjectSettings()

      return {
         isElementActive,
         userProfile,
         userProjectSettings
      }
   }
})
</script>

<style lang="scss">
a {
   text-decoration: none;
}

.env {
   text-transform: capitalize;
}

.router-link-active {
   font-weight: 700 !important;
}
</style>
