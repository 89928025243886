import { HttpClient, RequestParams } from "./base/BaseHttpClient";

export class DataSynchronizationApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags DataSynchronization
   * @name UpdateGoalData
   * @request GET:/api/data-sync/goal/{businessGoalId}
   * @response `200` `void` OK
   */
  updateGoalData = (
    businessGoalId: string,
    query?: {
      /**
       * @format int32
       * @default 60
       */
      days?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<void, any>({
        path: `/api/data-sync/goal/${businessGoalId}`,
        method: "GET",
        credentials: "include",
        query: query,
        ...params,
      })
      .then((m) => m.data);
}
