<template>
    <div class="mainview">
      <div
         v-show="isDataLoading"
      >
         <LoadingScreen />
      </div>
      <div
         v-if="!isDataLoading"
         class="min-h-screen flex relative lg:static"
      >
         <div class="min-h-screen flex flex-col relative flex-auto l-main-view-wrap">
            <div class="min-h-screen flex flex-col relative flex-auto l-main-view px-8">
               <slot />
            </div>
         </div>
      </div>
   </div>
  </template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import TopBar from '@/components/nav/TopBar.vue'
import SideMenu from '@/components/nav/SideMenu.vue'
import LoadingScreen from '@/components/shared/LoadingScreen.vue'

export default defineComponent({
   name: 'MainView',
   components: {
    LoadingScreen,
    TopBar,
    SideMenu,
},
   setup() {
      const isDataLoading = ref(false)

      const app = useNuxtApp()
      const router = useRouter()

   //    watch (
   //       () => router.currentRoute, 
   //       () => app.$appInsights.trackPageView(), 
   //       { deep: true, immediate: true}
   //   )

      return {
         isDataLoading
      }
   }
})
</script>

<style lang="scss">
.loading-spinner-container {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
}

.l-main-view {
   max-width: 1660px;
}

.l-main-view-wrap {
   overflow-x: auto;
   @media all and (min-width: 992px) {
      margin-left: 220px;
   }

}
</style>